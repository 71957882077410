.header
	display block
	position absolute
	right 0
	left 0
	&__wrap
		margin-left: 47px;
		margin-right: 130px;
		display: -ms-flexbox;
		display: flex;
		padding-top: 46px;
		@media screen and (max-width: 1120px)
			margin: 0 15px
		@media screen and (max-width: 680px)
			flex-wrap: wrap
	&__middle
		width 100%
		display flex
		justify-content: flex-end;
		@media screen and (max-width: 900px)
			flex-wrap: wrap
			justify-content: flex-start
		@media screen and (max-width: 768px)
			flex-wrap: wrap
			width: calc(100% - 146px)
			justify-content: center
	&__menu
		padding-top 10px
		margin-right 45px
		@media screen and (max-width: 1120px)
			margin-right: 30px
			.menu
				&__list
					margin-bottom: 15px
		@media screen and (max-width: 680px)
			margin-right: 0
	&__contacts
		display: flex
		align-items: center
		justify-content: space-between
		@media screen and (max-width: 768px)
			width: 100%
			justify-content: center
			padding-left: 15px
		&_align
			&_top
				align-items: flex-start
	&__phone
		font-size: 20px;
		color: rgb(255, 255, 255);
		font-weight: bold;
		line-height: 2;
		text-decoration: none;
		display: block;
		padding-left: 29px;
		letter-spacing: 0.6px;
		position relative
		@media screen and (max-width: 768px)
			margin-right: 25px
		&:after
			content: ''
			position absolute
			left 0
			top: 50%
			transform: translateY(-50%)
			width 11px
			height 25px
			background-image url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAZCAQAAABNuxw9AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiChkSIghGMqmRAAAAcElEQVQoz82SMQqAMBAEJ5JKhDQ+x8ZPWFv5Bxvf4tfsJeQHaxuTi7VTLsPCLecEAIFERodJB0zo7TZtT8TXsZNsuySyAyq5hCp75YbaDkLo65x/xonL3mRgay7Y7F44jFxoqsaVE4zM9Jy57Ow/eQC6E00AQ8dR8gAAAABJRU5ErkJggg==")
		span 
			@media screen and (max-width: 1120px)
				font-size: 18px
	&__email
		font-size: 18px;
		color: rgb(255, 255, 255);
		line-height: 1;
		text-decoration none
		text-align right
		display block
		margin-top: 15px;
		letter-spacing: 0.4px;
		@media screen and (max-width: 768px)
			margin-top: 0
	&__marker-mobile
		display: none
	&__bars-mobile
		display: none
	&__logo
		@media screen and (max-width: 1120px)
			margin-right: 25px
		@media screen and (max-width: 768px)
			width: 121px
	&__mobile
			&-right
				@media screen and (max-width: 1120px)
					width: 184px
				@media screen and (max-width: 863px)
					margin-top: 20px
				@media screen and (max-width: 768px)
					width: 100%
					align-items: center
					display: flex
					flex-wrap: wrap
					margin-top: 0

.page
	font-family: 'Roboto';
	font-weight: normal;
	font-style: normal;