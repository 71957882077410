.info
	margin-top: 90px
	@media screen and (max-width: 1000px)
		margin-top: 60px
	&__wrap
		max-width: 1360px
		width: 100%
		margin: 0 auto
		padding: 0 95px 0 60px
		@media screen and (max-width: 1199px)
			padding: 0 15px
	&__content
		display: flex
		flex-wrap: wrap
		margin-bottom: 30px
		@media screen and (max-width: 1000px)
			display: block
	&__left-content
		width: calc(54% - 18.5px)
		margin-right: 37px
		@media screen and (max-width: 1000px)
			width: 100%
			margin-right: 0
		.list
			&__title
				padding-left: 26px
				li
					padding-left: 35px
	&__right-content
		width: calc(46% - 18.5px)
		@media screen and (max-width: 1000px)
			width: 100%
		&_align
			&_center
				padding-top: 55px
				@media screen and (max-width: 1000px)
					padding-top: 30px
		.list
			margin-bottom: 95px
			padding-top: 36px
			@media screen and (max-width: 1000px)
				margin-bottom: 60px
			&__title
				padding-left: 48px
	&__image
		padding-left: 0
		margin-left: 28px
		@media screen and (max-width: 1199px)
			margin-left: 0
	&__note
		border: 2px dashed #000000
		padding: 48px 15px 55px 45px
		width: 100%
		max-width: 510px
		p
			line-height: 30px
	&__text
		padding-left: 48px
		@media screen and (max-width: 1199px)
			padding-left: 0
		h2
			margin-bottom: 34px
			font-size: 30px
			font-family: "Akrobat"
			font-weight: bold
		p
			font-size: 16px
			line-height: 30px
			color: #000000
	.price-site
		font-size: 48px
		font-family: "Akrobat"
		font-weight: bold
		margin-bottom: 30px
		padding-left: 48px
		letter-spacing: 1.05px
		@media screen and (max-width: 1340px)
			font-size: 42px
		@media screen and (max-width: 1199px)
			padding-left: 0
	.price-seo
		font-size: 30px
		font-family: "Akrobat"
		margin-bottom: 77px
		padding-left: 48px
		letter-spacing: 1.99px
		@media screen and (max-width: 1340px)
			font-size: 24px
		@media screen and (max-width: 1199px)
			padding-left: 0
		@media screen and (max-width: 1000px)
			margin-bottom: 30px
	.dedline
		font-size: 18px
		margin-bottom: 35px
		padding-left: 48px
		@media screen and (max-width: 1199px)
			padding-left: 0
	.button
		margin-left: 48px
		width: 300px
		@media screen and (max-width: 1199px)
			margin-left: 0
	img 
		max-width: 100%