.team-widget
	display none
	height 1287px
	background-color: #545d71
	background-position: -80%;
	background-image url(/team.png)
	background-repeat: no-repeat;
	background-size: cover;
	&__container
		max-width: 1265px
		display block
		margin: auto
		position relative
		.headline
			padding-top 43px
			margin-left: 43px;
			position: relative;
			.control
				position absolute
				display: flex;
				align-items: center;
				justify-content: center;
				right: 0;
				height: 50px;
				.arrow .line
					background #000000
				.arrow .line:after, .arrow .line:before
					background #000000
			.arrow
				position relative
		p.text
			font-size: 16px;
			color: #fff;
			line-height: 29px;
			text-align: left;
			font-weight: 300;
			width: 470px;
			padding-left: 7px;
			letter-spacing: .6px;
			word-break: break-all;
	&__link
		font-size: 24px;
		color: rgb(0, 0, 0);
		line-height: 1.25;
		text-decoration none
		&:hover
			text-decoration underline
		&-mobile
			display: none
	.for-mobile
		display: none


.team-name
	font-size: 138px;
	font-family: "Akrobat";
	color: rgb(255, 255, 255);
	letter-spacing: 1px
	transform: rotate(-90deg)
	text-transform: uppercase;
	line-height: 1.127;
	position absolute
	left -100%
	left: -25%;
	top: 100%;

