.form
	&__input
		margin-bottom: 23px
		margin-left: 10px
		input
			border: 1px solid rgb(191, 191, 191)
			background-color: #fff
			width: 338px
			height: 54px
			display: block
			max-width: 100%
			border-top-right-radius: 20px
			padding: 18px
	&__button
		margin-bottom: 36px
		margin-left: 10px
		button
			border-top-right-radius: 0
	&__agreement
		small 
			font-size: 14px
			color: #242424
			text-align: center
			display: block
			line-height: 1.3
			a 
				color: #728ac1
				font-size: 14px
				text-decoration: none