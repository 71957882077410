.widget
	display block
	margin-left: 3px;
	margin-bottom 24px
	.owl-carousel .owl-stage-outer
		padding-top 82px
		padding-bottom: 70px;
	.owl-carousel .owl-stage
		padding-left: 0 !important;
	&__item
		border-width: 2px;
		border-color: rgb(255, 255, 255);
		border-style: dashed;
		height: 248px;
		display: flex;
		flex-wrap: wrap;
		text-decoration none
		transition: all .4s
		cursor pointer
		&:hover
			transform: translateY(-25px);
			background: #fff;
			.widget__item--content .title
				color: #000
			.text-cell
				color: #000
			.widget__item--button .button
					background-color: #728ac1;
	&__item--button
		position absolute
		bottom: -27px;
		right: -12px;
		.button
			background-color: rgb(148, 158, 181);
			width: 200px;
			height: 56px;
	
	&__item--content
		position relative
		height: 100%;
		padding-left: 40px;
		padding-top: 42px;
		padding-right: 10px;
		width 100%
		.title
			font-size: 30px;
			color: rgb(255, 255, 255);
			line-height: normal;
			font-family: 'Akrobat';
			font-weight: 600;
			letter-spacing: 1.6px;
			font-style: normal;
			margin-bottom: 28px;
		.text-cell
			b
				display inline-block
				font-weight: bold;
			font-size: 18px;
			font-weight: 300;
			color: rgb(255, 255, 255);
			line-height: 2;




