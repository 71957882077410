.text
	display block
	&_type
		&_title
			color: rgb(255, 255, 255);
			font-weight: 400;
			line-height: 1.127;;
			font-size 48px;
			letter-spacing: -1.5px;
		&_subtitle
			font-size: 36px;
			color: rgb(255, 255, 255);
			line-height: 1.503;
			letter-spacing: -1.5px;


	&_iner
		&_top--bottom
			margin-top 35px
			margin-bottom 35px

	&_color
		&_black
			color: #000000