.menu
	display block
	&__list
		display inline-block
		@media screen and (max-width: 768px)
			display: block
		&:hover
			> a
				color: #728ac1
		&:first-child
			display: none
			@media screen and (max-width: 768px)
				display: block
		a
			display block
			font-size: 18px;
			color: rgb(255, 255, 255);
			line-height: 1;
			text-decoration none
			transition: all .4s
			position relative
			letter-spacing: 0.5px;
			display: block;
			padding-right 90px
			&:after
				content: ''
				position absolute
				right 20px
				top 10px
				width 43px
				height 1px
				background #fff
			@media screen and (max-width: 1500px)
				padding-right: 40px
				&::after
					@media screen and (max-width: 1500px)
						width: 25px
						right: 7px
			@media screen and (max-width: 1230px)
				padding-right: 10px
				font-size: 16px
				&::after
					@media screen and (max-width: 1230px)
						display: none
		ul
			position: absolute
			top: 110px
			display: none
			@media screen and (max-width: 768px)
				position: static
				display: block
				margin: 20px 40px
		&.dropdown
			> a
				&:hover
					span
						display: flex
						flex-direction: column
						align-items: center
						position: relative
						@media screen and (max-width: 768px)
							align-items: flex-start
						&::after
							content: ""
							width: 1px
							height: 25px
							background: #ffffff
							position: static
							margin-top: 5px
							@media screen and (max-width: 768px)
								display: none
		&:last-child
			a
				padding-right 0
				&:after
					display none
	.menu-toggle
		&.full
			display: none
	.dd-toggle
		display: none