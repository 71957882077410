.button
	display block
	text-decoration none
	display block
	outline: none
	border: none 
	&_with-icon
		display: flex
		align-items: center
		justify-content: center
	&_theme
		&_blue
			color: #fff
			background-color: rgb(114, 138, 193);
			width: 340px;
			max-width: 100%
			height: 56px;
			text-align center;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 20px;
			letter-spacing: 1.1px;
			transition: all .4s
			&:hover
				background-color #949eb5
			> span
				display inline-block
				vertical-align middle
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				white-space nowrap
				padding 0 12px
			&:after
				content: ''
				display inline-block
				vertical-align middle
				height 100%
		&_no-bg
			width: 340px
			max-width: 100%
			height: 56px
			border: 2px solid #728ac1
			color: #728ac1
			text-align center;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 20px;
			letter-spacing: 1.1px;
			transition: all .4s
			&:hover
				background-color #728ac1
				color: #ffffff
			> span
				display inline-block
				vertical-align middle
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				white-space nowrap
				padding 0 12px
			&:after
				content: ''
				display inline-block
				vertical-align middle
				height 100%