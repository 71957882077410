.page-landing
	.widget-list
		.text
			&_type
				&_subtitle
					font-size: 48px
					&::after
						left: -56px
						top: 57%
					&::before
						left: -103.7%
						bottom: 16px
	.widget
		.owl-carousel
			.owl-stage-outer
				padding-top: 82px
	.portfolio-widget
		padding-top: 30px
		padding-bottom: 105px
		.text
			&_type
				&_title
					font-family: "Akrobat"
	.briff
		padding-top: 79px
		padding-bottom: 179px
		@media screen and (max-width: 1199px)
			padding-left: 40px 15px
		.widget-list
			&__subtitle
				padding-left: 13px
				@media screen and (max-width: 1199px)
					padding-left: 50px
				&::before
					bottom: 21px
					left: -104.7%
				&::after
					top: 44%
					left: -4.4%
					@media screen and (max-width: 1199px)
						left: 0
		.button
			&_with-icon
				margin-right: 33px
				padding-left: 15px
	.footer
		&__wrap
			max-width: 1370px
		&__main
			.middle
				padding-left: 144px
				padding-top: 13px
				.menu
					&:not(.first)
						margin-top: 0
					&__list
						a
							&::after
								margin: 0 11.5px