.briff
	display block
	background #2b354d
	padding: 76px 95px 84px
	display: flex
	align-items: center
	justify-content: center
	@media screen and (max-width: 680px)
		padding: 40px
	&__wrap
		width: 100%
		max-width: 1218px
		margin: auto
		display: flex
		justify-content: space-between
		flex-wrap: wrap
	.widget__item--button
		position: static
		.button 
			width: 300px
	.widget-list__subtitle
		padding-left: 25px
		letter-spacing: -1.5px
	.button_with-icon
		letter-spacing: 3px
		text-align: left
		padding-left: 7px
		padding-top: 0
		height: 60px
		width: 300px
		margin-right: 23px
		@media screen and (max-width: 1199px)
			margin-left: 25px
			margin-top: 20px
		img 
			margin-right: 5px
	.steps
		padding-left: 15px
		@media screen and (max-width: 1199px)
			width: 100%
	&_bg-image
		&_true
			background-image: url(/promo_type_main.jpg)
			background-repeat: no-repeat