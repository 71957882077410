.widget-list
	margin-left: 19.2%;
	margin-top: 200px;
	margin-bottom: 212px;
	@media screen and (max-width: 1199px)
		margin: 80px 15px 40px
	&.bg
		&_yes
			background: #4d5972
			padding-left: 19.2%
			padding-top: 60px
			padding-bottom: 60px
			margin: 82px 0 0
	&__subtitle
		position relative
		@media screen and (max-width: 600px)
			padding-left: 25px
		&:after
			width 27px
			height 27px
			background rgb(114, 138, 193)
			transform translateY(-50%)
			left: -3.15%;
			top 50% 
			
		&:before
			background: #ffffff
			width 100%
			left: -103.15%;
			height: 3px;
			bottom: 14px;
		&:after, &:before
			content: ''
			position absolute
	.portfolio-widget
		padding-top: 29px
		padding-bottom: 107px
	.briff
		padding-top: 78px