.map
	position: relative
	@media screen and (max-width: 1199px)
		display: flex
		flex-direction: column
	.text_iner_top--bottom
		margin-bottom: 70px
		margin-left: 15px
		letter-spacing: -1.5px
		@media screen and (max-width: 600px)
			margin-left: 0
	&__yandex
		height: 100%
		width: 100%
		position: absolute
		left: 0
		top: 0
		@media screen and (max-width: 1199px)
			position: relative
			height: 430px
			order: 1
	&__wrap
		max-width: 1375px
		width: 100%
		margin: auto
	&__contact
		width: 691px
		max-width: 100%
		background #545d71
		color: #fff
		padding: 60px 50px 60px 90px
		position: relative
		@media screen and (max-width: 1199px)
			width: 100%
			padding: 15px
	&__item
		margin-bottom: 73px
		display: flex
		align-items: flex-start
		position: relative
	&__icon
		position: absolute
		left: 0
	&__text 
		padding-left: 92px
		letter-spacing: 1.5px
		.label
			font-family: "Akrobat"
			font-size: 24px
			margin-bottom: 17px
		.value
			font-size: 30px
			line-height: 1.2
			&.social-icons
				a 
					margin-right: 30px