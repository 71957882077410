.footer
	background: #040404
	position: relative
	z-index: 100
	@media screen and (max-width: 1375px)
		padding: 15px
	&__wrap
		max-width: 1360px
		width: 100%
		margin: auto
		padding: 18px 0 20px
	&__main
		margin-bottom: 80px
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		@media screen and (max-width: 900px)
			margin-bottom: 30px
		.middle
			padding-top: 15px
		.right
			@media screen and (max-width: 900px)
				width: 100%
				margin-top: 20px
	&__info
		display: flex
		flex-wrap: wrap
		align-items: center
		.left
			@media screen and (max-width: 900px)
				width: 100%
				order: 1
				margin-top: 30px
		.copy
			small 
				font-size: 12px
				color: #fff
				span 
					display: block
					margin-top: 2px
		.social-icons
			margin-left: 400px
			@media screen and (max-width: 900px)
				margin-left: 0
			a 
				margin-right: 30px
	&__menu
		padding-top 10px
		margin-right 45px
		@media screen and (max-width: 1000px)
			margin-right: 0
			.menu
				&:not(.first)
					margin-top: 0
				&__list
					margin-bottom: 15px
		@media screen and (max-width: 768px)
			display: none
	.header__email
		@media screen and (max-width: 900px)
			text-align: left
			&::after
				display: none
	.header__phone
		@media screen and (max-width: 800px)
			margin-right: 20px
			padding-left: 0
			&::after
				display: none