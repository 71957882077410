@media screen and (max-width: 768px)
    .header
        &__logo
            display: none
        &__menu
            display: none
            margin: 0
            order: 1
            background-color: #000000
            padding: 15px
            width: 100%
        &__contacts
            a 
                &:not(:last-of-type)
                    margin-right: 37px
        &__phone
            display: inline-block
            span 
                display: none
            &:after
                position: static
                background-image: url(/teleph.png)
                width: 36px
                height: 35px
                display: inline-block
                transform: none
                vertical-align: middle
        &__email
            position: relative
            display: inline-block
            margin-top: 0
            span 
                display: none
            &:after
                content: ""
                position: static
                background-image: url(/mail.png)
                width: 42px
                height: 32px
                display: inline-block
                vertical-align: middle
        &__marker-mobile
            display: inline-block
            &::after
                content: ""
                background-image: url(/navigation.png)
                width: 25px
                height: 35px
                display: inline-block
                vertical-align: middle
        &__bars-mobile
            display: inline-block
            text-decoration: none
            span 
                color: #ffffff
                font-size: 40px
        &__middle
            flex-wrap: wrap
            width: 100%
        &__contacts
            flex-basis: 100%
            background #040404
            padding: 15px
        &__wrap
            margin: 0
            padding: 0
        &__mobile
            &-right
                justify-content: flex-end
@media screen and (max-width: 480px) 
    .promo
        padding: 15px
        &_type
            &_main
                background-image: url("/planeta_mobile.png"), url("/bg_gradient.png")
                background-size: 218px, cover
                background-repeat: no-repeat
                background-position: 0 60px, 0 0
                .promo__right-content
                    padding: 0 0
                    margin-top: 0
                    .text
                        margin-top: 0
                        padding: 0 18px
                        font-size: 18px
                        span 
                            display: block
                .promo__button
                    margin-top: 28px
                .promo__wrap
                    padding-top: 110px
                &-portfolio
                    .promo__wrap
                        padding-top: 60px
                    .content
                        .image
                            &.size
                                &_half
                                    .first
                                        width: 100%
                                        margin-right: 0
                                        margin-bottom: 18px
                                        flex-wrap: wrap
                                        flex-direction: column
                                    > *
                                        width: 100%
                        .left
                            &:nth-child(3)
                                width: 100%
                            &:nth-child(5)
                                width: 100%
                                .image
                                    img 
                                        &.first
                                            margin-right: 0
                                        &:last-child
                                            margin-top: 0
                        .right
                            &:nth-child(2)
                                width: 100%
                                margin-right: 0
                            &:nth-child(4)
                                width: 100%
                                margin-right: 0
                        .list
                            li
                                margin-right: 40px
        &__content
            flex-wrap: wrap
        &__logo
            width: 100%
            text-align: center
            margin-bottom: 34px
            img 
                width: 232px
                max-width: 100%
    .widget
        &__item
            width: 211px
            height: 189px
            border: none
            &:hover
                .widget
                    &__item--button
                        .button
                            background-color: #949eb5
        &__item--content
            padding: 26px 9px 40px 26px
            background #fff
            display: flex
            flex-wrap: wrap
            .title
                color: #000000
                font-size: 20px
                height: 80px
                margin-bottom: 0
            .text-cell
                color: #000000
                font-size: 16px
                margin-bottom: 0
        &__item--button
            bottom: -35px
            right: 30px
            .button
                width: 150px
                height: 50px
                background-color: #728ac1
        .owl-carousel
            .owl-stage-outer
                padding-top: 45px
                padding-bottom: 45px
    .widget-list
        margin: 65px 0 0
        &__subtitle
            &:before
                display: none
            &:after
                transform: none
                top: 10px
        .text
            &_type
                &_title
                    text-align: center
    .text
        &_type
            &_title
                font-size: 36px
            &_subtitle
                padding-left: 45px
                font-size: 30px
                &:after
                    left: 0
    .arrow
        display: none
    .portfolio-widget
        padding-bottom: 15px
        &__wrap
            margin-left: 0
        &__tiles
            flex-wrap: wrap
        &__tile
            &.size-3
                width: 100%
                margin-right: 0
                order: 1
                padding-bottom: 0
            &.size-6
                width: 100%
                padding-right: 0
                margin-right: 0
                &:first-child
                    margin-bottom: 10px
                    margin-right: 0
            &.size-9
                width: 100%
                margin-bottom: 10px
            &.size-12
                width: calc(50% - 5px)
                height: auto
                margin-right: 10px
                margin-bottom: 0
            &.for-order
                height: auto
                width: calc(50% - 5px)
                flex-wrap: wrap
        .text
            &_type
                &_title
                    padding-left: 32px
        &__link
            text-decoration: none 
            background-color: #728ac1
            width: 100%
            height: 56px
            text-align: center
            border-top-right-radius: 20px
            border-bottom-left-radius: 20px
            letter-spacing: 1.1px
            transition: all 0.4s
            padding-top: 13px
            margin-top: 30px
            &:hover
                text-decoration: none 
                background-color: #949eb5
    .team-name
        display: none
    .team-widget
        height: auto
        background-image: linear-gradient(#000, #000);
        background-size: 100% 80px
        background-position-y: 100%
        &__container
            padding: 15px 15px 0
            .headline
                margin: 0
                padding: 0
                .control
                    display: none
            .text
                &_type
                    &_title
                        padding-left: 48px
            p.text 
                width: 350px
                max-width: 100%
                word-break: break-word
                font-size: 18px
                padding: 0
        .for-mobile
            display: block
            width: 100%
            margin-left: 15px
            margin-top: 35px
        &__link
            &-mobile 
                display: block
                text-decoration: none 
                background-color: #728ac1
                width: 100%
                max-width: 100%
                height: 56px
                text-align: center
                border-top-right-radius: 20px
                border-bottom-left-radius: 20px
                letter-spacing: 1.1px
                line-height: 1.25
                font-size: 24px
                transition: all 0.4s
                padding-top: 13px
                margin-top: 30px
                color: #fff
                margin: 25px 15px 0
                &:hover
                    text-decoration: none 
                    background-color: #949eb5
    .partners
        padding-bottom: 60px
        &__wrap
            padding: 15px
        .text
            &_type
                &_title
                    margin-left: 48px
    .briff
        padding: 60px 15px 80px
        .text
            &_type
                &_subtitle
                    padding: 0
                    text-align: center
                    width: 320px
                    max-width: 100%
                    margin: 0 auto 40px
                    &:after
                        display: none
        .button
            margin-right: 0
            padding: 0
            width: 100%
            &_theme
                &_blue
                    span 
                        font-size: 24px
                        span 
                            display: none
                    img 
                        display: none
    .map
        background-image: none
        padding-bottom: 60px
        background #545d71
        &__contact
            padding: 15px
            max-width: 100%
        .text
            &_type
                &_title
                    margin-left: 0
                    padding-left: 48px
                    margin-bottom: 50px
        &__icon
            img 
                width: 36px
        &__text
            padding-left: 51px
            &.address
                .value
                    font-size: 18px
                    max-width: 300px
                    line-height: 1.5
            .social-icons
                margin-top: 45px
                a 
                    display: inline-block
                    margin-bottom: 30px
                    margin-right: 33px
                    img 
                        display: inline-block
        &__mobile
            display: block
            height: 440px
            overflow: hidden
            img 
                width: 100%
    .footer
        display: none
    .menu
        &__list
            display: block
            margin-bottom: 15px
            a 
                padding-right: 0
                &:after
                    display: none
    .site-type
        .text
            &_type
                &_subtitle
                    &::after
                        top: 30%
                        left: 0
@media screen and (max-width: 380px)
    .map
        &__text
            .value
                font-size: 24px
                &.social-icons
                    a
                        margin-right: 15px
@media screen and (max-width: 320px)
    .header
        &__contacts
            a
                &:not(:last-of-type)
                    margin-right: 15px
    .promo
        &_type
            &_main
                .promo__right-content
                    .text
                        font-size: 16px
    .portfolio-widget
        &__link
            font-size: 18px
            padding-top: 16px
        .text
            &_type
                &_title
                    padding-left: 0
    .team-widget
        &__link-mobile
            font-size: 18px
            padding-top: 16px
        .text
            &_type
                &_title
                    padding-left: 0
    .partners
        .text
            &_type
                &_title
                    margin-left: 0
    .briff
        .button_theme_blue
            span 
                font-size: 18px