.steps
	margin-top: 60px
	&__wrap
		max-width: 1360px
		width: 100%
		margin: auto
	&__buttons
		display: flex
		flex-wrap: wrap
		justify-content: center
		margin-top: 60px
		margin-left: 95px
		@media screen and (max-width: 1199px)
			margin: 50px 0 0
			justify-content: flex-start
		.button
			margin-right: 10px
			width: 300px
			@media screen and (max-width: 1199px)
				margin-bottom: 15px
	.text
		&_type
			&_title
				margin-bottom: 105px
				@media screen and (max-width: 1199px)
					margin-bottom: 40px
	.items
		display: flex
		flex-wrap: wrap
		width: calc(100% + 20px)
		counter-reset: count
		@media screen and (max-width: 1199px)
			flex-wrap: wrap
			width: 100%
		.item
			width: 378px
			padding: 10px 36px
			border: 2px dashed #ffffff
			margin-right: 20px
			margin-bottom: 53px
			min-height: 315px
			&::before
				content: counter(count)
				counter-increment: count
				display: block
				color: #949eb5
				font-family: "Akrobat"
				font-weight: bold
				font-size: 80px
				margin-bottom: 22px
			p
				font-family: "Akrobat"
				font-size: 30px
				font-weight: 600
				line-height: 40px
				color: #ffffff
				margin-bottom: 15px
				padding-left: 5px
			&.border
				&_no
					border: none 
			ul
				margin-top: 32px
				padding-left: 12px
				li
					color: #ffffff
					line-height: 30px
		.text
			width: 776px
			display: flex
			align-items: flex-start
			justify-content: center
			margin-right: 20px
			padding-top: 73px
			padding-left: 32px
			@media screen and (max-width: 1199px)
				order: 1
				padding: 0
				justify-content: flex-start
			p
				font-size: 16px
				font-family: "Roboto"
				line-height: 30px
				color: #ffffff
				max-width: 555px