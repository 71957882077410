.list
	padding-top: 38px
	margin-bottom: 40px
	&__title
		margin-bottom: 33px
		font-size: 30px
		font-family: "Akrobat"
		font-weight: bold
	li
		line-height: 30px
		color: #000000
		position: relative
		text-indent: 20px
		padding-left: 31px
		@media screen and (max-width: 1199px)
			padding-left: 39px
		&::before
			content: ""
			display: inline-block
			width: 39px
			height: 1px
			background: #000000
			position: absolute
			left: -8px
			top: 15px
			margin-right: 20px
			@media screen and (max-width: 1199px)
				left: 0