.partners
	display block
	background #000000
	padding-bottom: 119px
	@media screen and (max-width: 1199px)
		padding: 0 15px 100px
	&__wrap
		max-width: 1170px
		margin auto
		padding-top 67px
		display block
		width 100%
		@media screen and (max-width: 1199px)
			padding-top: 20px
	&__items
		margin-top:52px
	.owl-stage
		display: flex
		align-items: center