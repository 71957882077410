.popup
	&__wrap
		max-width: 442px
		width: 100%
		min-height: 424px
		margin: auto
		background #fff
		padding: 37px 43px 0px
		position: relative
	&__title
		color: #242424
		font-size: 30px
		font-family: "Akrobat"
		font-weight: bold
		line-height: 1.2
		margin-bottom: 40px
		margin-left: 10px
		width: 160px
		max-width: 100%
		letter-spacing: 2.3px
	&__close
		position: absolute
		right: 25px
		top: 25px
		cursor: pointer
.mfp-auto-cursor 
	.mfp-content
		max-width: 442px