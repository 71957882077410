.portfolio-widget
	display block
	max-width: 1265px
	width 100%
	padding-bottom: 98px;
	margin:auto
	@media screen and (max-width: 600px)
		padding-bottom: 20px
	&__wrap
		margin-left 45px
		@media screen and (max-width: 1199px)
			margin-left: 15px
	&__tiles
		display flex
		height 687px
		margin-top: 57px;
	&__tile--content
		background rgb(154, 161, 179);
		height 100%
	&__tile
		overflow hidden
		&.size-12
			width 100%
			height 50%
			margin-bottom: 20px
			@media screen and (max-width: 1199px)
				margin-right: 20px
		&.size-3
			width 33.3333333333%
			margin-right:  20px
			padding-bottom 0
		&.size-9
			width 66.6666666667%
			display: flex;
			padding-bottom 0
			flex-wrap: wrap;
		&.size-6
			width 50%
			padding-right: 20px;
			padding-bottom 0
			@media screen and (max-width: 1199px)
				padding-right: 0
				margin-right: 20px
			&:last-child
				padding-right 0
		&.for-order
			width: 100%
			height: 334px
			display: flex

.portfolio-widget__link
	font-size: 24px;
	color: rgb(255, 255, 255);
	line-height: 1.25;
	display block
	margin-left: auto
	text-decoration none
	text-align right
	width: 300px;
	margin-top: 32px;
	text-align: right;
	@media screen and (max-width: 1199px)
		margin-right: 20px
	&:hover
		text-decoration underline
	&_color
		&_black
			color: #000000