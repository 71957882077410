.site-type
	margin-top: 80px
	&__wrap
		max-width: 1360px
		width: 100%
		margin: auto
	.text
		&_type
			&_subtitle
				color: #000000;
				font-size: 48px
				font-weight: 400
				&::after
					width: 20px
					height: 20px
					top: 55%
					left: -7.15%
	.items
		padding-left: 95px
		display: flex
		flex-wrap: wrap
		@media screen and (max-width: 1000px)
			padding-left: 45px
		@media screen and (max-width: 600px)
			padding-left: 15px
		.item
			width: 520px
			margin-bottom: 50px
			@media screen and (max-width: 1199px)
				width: calc(50% - 20px)
			@media screen and (max-width: 1000px)
				width: calc(50% - 10px)
			@media screen and (max-width: 600px)
				width: 100%
				padding-bottom: 60px
			&:nth-of-type(odd)
				margin-right: 144px
				@media screen and (max-width: 1199px)
					margin-right: 40px
				@media screen and (max-width: 1000px)
					margin-right: 20px
				@media screen and (max-width: 600px)
					margin-right: 0
			.text
				margin-bottom: 35px
				@media screen and (max-width: 1199px)
					font-size: 32px
				@media screen and (max-width: 600px)
					padding-left: 30px
			.label
				font-size: 30px
				line-height: 40px
				color: #000000
				font-weight: 400
				font-family: "Akrobat"
				margin-bottom: 40px
			.content
				padding: 50px 58px 40px
				border: 2px dashed #000000
				min-height: 69.3%
				display: flex
				flex-direction: column
				@media screen and (max-width: 1000px)
					padding: 50px 15px 40px
				ul
					margin-bottom: 40px
					li
						font-size: 18px
						line-height: 35px
						@media screen and (max-width: 1000px)
							font-size: 16px
				.also
					font-size: 18px
					line-height: 30px
					font-weight: bold
					margin-top: auto
			.info
				margin-top: 20px
				display: flex
				flex-wrap: wrap
				justify-content: space-between
				align-items: flex-start
				p
					color: #000000
					font-size: 18px
					line-height: 36px
					span 
						font-weight: bold
			.button
				width: 200px
				height: 56px
				@media screen and (max-width: 1200px)
					margin-left: 0
					margin-top: 10px
	.widget-list
		&__subtitle
			&::before
				display: none