.promo
	display block
	min-height: 100vh
	@media screen and (max-width: 1000px)
		padding: 0 15px
	&_type
		&_main
			background-color: #232b41
			background-image: url(/promo_type_main.jpg)
			background-size cover
			.promo__content
				display flex
				@media screen and (max-width: 768px)
					flex-wrap: wrap
				@media screen and (max-width: 600px)
					justify-content: center
			.promo__wrap
				max-width: 940px
				display block
				margin: auto
				padding-top 216px
				@media screen and (max-width: 600px)
					padding-top: 210px
			.promo__right-content
				display inline-block
				padding-left: 120px;
				vertical-align: top;
				@media screen and (max-width: 1000px)
					width: calc(50% - 10px)
					padding-left: 50px
				@media screen and (max-width: 600px)
					padding-left: 0
					width: 100%
					margin-top: 30px
				.text
					font-size: 16px;
					color: rgb(255, 255, 255);
					line-height: 30px;
					margin-top -9px
					text-align: left;
					word-break: break-all;
					padding-left 3px
					@media screen and (max-width: 1000px)
						word-break: break-word
					@media screen and (max-width: 600px)
						text-align: center
					span 
						@media screen and (max-width: 600px)
							display: block
			.promo__logo
				@media screen and (max-width: 1000px)
					width: calc(50% - 10px)
					img 
						max-width: 100%
				@media screen and (max-width: 600px)
					width: 60%
			.promo__button
				margin-top 35px
				@media screen and (max-width: 600px)
					margin: 20px auto
				span 
					@media screen and (max-width: 1000px)
						height: auto
						text-overflow: inherit 
						white-space: inherit 
						padding: 8px 12px
						line-height: 1.3
					@media screen and (max-width: 600px)
						padding: 12px
			h1
				font-size: 80px
				color: #fff
				font-family: "Akrobat"
				font-weight: bold
				margin-bottom: 30px
				@media screen and (max-width: 600px)
					font-size: 48px
			p
				color: #ffffff
				line-height: 1.5
				span 
					display: block
					@media screen and (max-width: 1120px)
						display: inline
			
			&-landing
				min-height: 0
				.promo__wrap
					max-width: 1150px
					padding-top: 200px
					padding-bottom: 100px
					padding-left: 23px
					@media screen and (max-width: 768px)
						padding-left: 0
				.promo__left-content
					@media screen and (max-width: 768px)
						text-align: center
				.promo__right-content
					padding-top: 13px
					padding-left: 94px
					@media screen and (max-width: 768px)
						padding-left: 0
						width: 100%
						display: flex
						padding-top: 0
						margin-top: 30px
						flex-wrap: wrap
						justify-content: center
				.promo__button
					margin-bottom: 10px
					margin-top: 0
				.button
					width: 300px
					@media screen and (max-width: 768px)
						margin-bottom: 20px
						margin-right: 15px
				p
					line-height: 30px
					letter-spacing: 0.08px
			&-site
				min-height: 0
				.promo__wrap
					max-width: 1150px
					padding-top: 200px
					padding-bottom: 100px
				.promo__right-content
					padding-top: 15px
				.promo__button
					margin-bottom: 10px
					margin-top: 0
			&-portfolio
				padding-bottom: 140px
				@media screen and (max-width: 1199px)
					padding-bottom: 40px
				img 
					max-width: 100%
					width: 100%
				.promo__wrap
					max-width: 1170px
				.list
					width: 50%
					margin-right: 18px
					margin-bottom: 0
					padding: 0
					li
						padding-left: 0
						text-indent: 0
						&::before
							display: none
						&.text
							&::before
								background: #ffffff
								width: 100%
								left: -112.15%
								height: 3px
								top: 33px
								display: block
								@media screen and (max-width: 1199px)
									display: none
							&::after
								left: -50px
								@media screen and (max-width: 1199px)
									display: none
						a
							color: #ffffff
							text-decoration: none 
							font-size: 24px
							line-height: 50px
							@media screen and (max-width: 650px)
								font-size: 20px
				.content
					display: flex
					flex-wrap: wrap
					.left
						width: 766px
						max-width: 100%
						margin-right: 18px
						&:nth-child(1)
							@media screen and (max-width: 1199px)
								width: 100%
								margin-right: 0
						&:nth-child(3)
							@media screen and (max-width: 1199px)
								width: calc(50% - 9px)
								margin-right: 0
						&:nth-child(5)
							@media screen and (max-width: 1199px)
								width: calc(50% - 9px)
								margin-right: 0
							.image
								img 
									@media screen and (max-width: 1199px)
										width: 100%
									&.first
										@media screen and (max-width: 1199px)
											margin-right: 0
									&:last-child
										@media screen and (max-width: 1199px)
											margin-top: 18px
					.right
						width: 386px
						display: flex
						flex-wrap: wrap
						&:nth-child(2)
							@media screen and (max-width: 1199px)
								width: calc(50% - 9px)
								margin-right: 18px
							.image
								@media screen and (max-width: 1199px)
									width: 100%
						&:nth-child(4)
							@media screen and (max-width: 1199px)
								width: calc(50% - 9px)
								margin-right: 18px
							.image
								@media screen and (max-width: 1199px)
									width: 100%
						&:nth-child(6)
							@media screen and (max-width: 1199px)
								width: 100%
							.image
								@media screen and (max-width: 1199px)
									width: 100%
					.image
						margin-bottom: 18px
						&.size
							&_half
								display: flex
								flex-wrap: wrap
								> *
									width: calc(50% - 9px)
								.first
									margin-right: 18px
									@media screen and (max-width: 1199px)
										display: flex
										flex-direction: column
										align-items: center
										text-align: left
							&_full
								display: flex
								flex-wrap: wrap