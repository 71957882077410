.arrow
	display flex
	align-items center
	justify-content center
	width 170px
	position relative
	flex-wrap: wrap
	height 50px
	position: absolute;
	right: 12%;
	top: 50%;
	transform: translateY(-50%);
	@media screen and (max-width: 600px)
		display: none
	.line
		width 100%
		height 1px
		background #fff
		position relative
		display block
		&:after
			top: 11px;
			display: block;
			transform: rotate(-40deg);
			right: -5px 
			display block
		&:before
			transform: rotate(40deg);
			display: block;
			top: -11px;
			display block
			right -5px
		&:after , &:before
			content: ''
			background #ffffff
			width 35px
			height 1px
			position absolute
	.circle
		border-width: 2px;
		border-color: rgb(255, 255, 255);
		border-style: solid;
		border-radius: 50%;
		display block
		width: 32px;
		height: 32px;
	.circle
		top: 50%;
		position: absolute;
		transform: translateY(-50%) translateX(50%);
		right: 50%;
.slideRight
	animation-name: slideRight
	animation-duration: 2s
	animation-iteration-count: infinite;
	animation-timing-function: ease-out
	visibility: visible !important
@keyframes slideRight
	0%
		right 50%
	50%
		right 100%
	100%
		right 50%